<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  mounted() {
    if (!this.isMobile()) {
      window.location.href = 'https://vshuodata.com'
    }
  },
  methods: {
    isMobile() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];
  
      return toMatch.some((pattern) => {
        return navigator.userAgent.match(pattern);
      });
    },
  }
}
</script>
<style lang="scss">
</style>
