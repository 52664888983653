import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect:'/home',
    component: ()=> import('../views/index.vue'),
    children:[
      {
        path: '/home',
        name: 'HomeView',
        meta:{
          title:'首页',
        },
        component: ()=> import('../views/HomeView.vue'),
      },
      {
        path: '/about',
        name: 'about',
        meta:{
          title:'关于',
        },
        component: ()=> import('../views/about.vue'),
      },
      {
        path: '/product',
        name: 'product',
        meta:{
          title:'产品',
        },
        component: ()=> import('../views/product.vue'),
      },
      {
        path: '/word',
        name: 'word',
        meta:{
          title:'文档',
        },
        component: ()=> import('../views/word.vue'),
      },
      {
        path: '/price',
        name: 'price',
        meta:{
          title:'价格',
        },
        component: ()=> import('../views/price.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta:{
      title:'登录',
    },
    component: ()=> import('../views/login.vue'),
  },
  {
    path: '/register',
    name: 'register',
    meta:{
      title:'注册',
    },
    component: ()=> import('../views/register.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
